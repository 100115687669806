<div class="table-content">
    <mat-list>
        <mat-list-item>{{ 'stocks.table.selectedStocks' | translate }}</mat-list-item>
    </mat-list>
    <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="ticker">
            <mat-header-cell *matHeaderCellDef>{{ 'stocks.table.ticker' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.ticker.toUpperCase() }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef>{{ 'stocks.table.quantity' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.quantity }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="strategy">
            <mat-header-cell *matHeaderCellDef>{{ 'stocks.table.strategy' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" title="{{ row.strategy }}"
                ><span class="ellipsis">{{ row.strategy }}</span></mat-cell
            >
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="actions"></mat-header-cell>
            <mat-cell *matCellDef="let id = index; let row">
                <button mat-raised-button color="primary" (click)="removeRow(id)" *ngIf="!isSessionExists">
                    <span>{{ 'stocks.table.elements.removeButton' | translate }}</span>
                </button>
                <button
                    mat-raised-button
                    color="primary"
                    class="session-delete-button"
                    [ngStyle]="{ 'min-width': innerWidth > 600 ? 'fit-content' : '6em' }"
                    (click)="updateStock(id, row.id)"
                    *ngIf="isSessionExists"
                    [disabled]="!row.actions"
                >
                    <span class="session-text">{{
                        (row.actions ? 'stocks.table.elements.removeButton' : 'stocks.table.elements.excludeButton')
                            | translate
                    }}</span>
                </button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"> </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div *ngIf="!this.data || this.data?.length === 0" class="empty-error">
        {{ this.errorMessage }}
    </div>
    <div class="start-button">
        <button
            *ngIf="!this.isSessionExists && !this.marketStatus"
            mat-raised-button
            color="primary"
            [disabled]="!this.data || this.data?.length === 0"
            (click)="uploadFinalData()"
        >
            {{ 'stocks.elements.startButton' | translate }}
        </button>
    </div>
</div>
