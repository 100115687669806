import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';

import { StatusService } from '../services/status.service';
import { TradingService } from '../services/trading.service';

@Injectable()
export class DashboardResolver implements Resolve<any> {
    constructor(public tradingService: TradingService, public statusService: StatusService) {}

    resolve() {
        const stocks = this.tradingService.getStocks();
        const market = this.statusService.getMarketStatus();
        const balance = this.statusService.getBalanceStatus();
        const user = this.statusService.getUserStatus();
        return of({ stocks, market, balance, user });
    }
}
