import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private readonly tokenStorage: TokenStorageService, private readonly router: Router) {}

    canActivate(): boolean {
        if (this.tokenStorage.getToken() === null) {
            void this.router.navigate(['auth']).then(() => {
                window.location.reload();
            });
        }
        return !(this.tokenStorage.getToken() === null);
    }
}
