import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StocksUpdateService {
    constructor(readonly http: HttpClient) {}

    updateStock(id: string, isActive: boolean): Observable<any> {
        return this.http.put(environment.baseApiUrl + 'stocks/update', {
            id,
            isActive,
        });
    }
}
