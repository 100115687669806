import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_NAME = 'user-email';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';

@Injectable({
    providedIn: 'root',
})
export class TokenStorageService {
    signOut(): void {
        window.sessionStorage.clear();
    }

    public saveUserName(username: string): void {
        window.sessionStorage.removeItem(USER_NAME);
        window.sessionStorage.setItem(USER_NAME, username);
    }

    public getUsername(): string | null {
        return window.sessionStorage.getItem(USER_NAME);
    }

    public saveToken(token: string): void {
        window.sessionStorage.removeItem(TOKEN_KEY);
        window.sessionStorage.setItem(TOKEN_KEY, token);
    }

    public getToken(): string | null {
        return window.sessionStorage.getItem(TOKEN_KEY);
    }

    public saveRefreshToken(token: string): void {
        window.sessionStorage.removeItem(REFRESHTOKEN_KEY);
        window.sessionStorage.setItem(REFRESHTOKEN_KEY, token);
    }

    public getRefreshToken(): string | null {
        return window.sessionStorage.getItem(REFRESHTOKEN_KEY);
    }
}
