import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    constructor(
        readonly tokenStorage: TokenStorageService,
        private readonly router: Router,
        private readonly authService: AuthService,
        public readonly translate: TranslateService
    ) {
        translate.addLangs(['en']);
        translate.setDefaultLang('en');
    }

    unsubscribe = new Subject<void>();
    username: string | null = '';

    ngOnInit(): void {
        this.username = this.tokenStorage.getUsername();
    }

    logout(): void {
        this.authService
            .logout()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                void this.router.navigate(['auth']).then(() => {
                    this.tokenStorage.signOut();
                    window.location.reload();
                });
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
    }
}
