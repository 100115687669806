/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { UserSession } from '../model/user-session.model';
import { StockModel } from '../model/stock-model';

@Injectable({ providedIn: 'root' })
export class TradingService {
    constructor(readonly http: HttpClient) {}

    uploadFinalData(data: any): Observable<any> {
        return this.http.post(environment.baseApiUrl + 'trading/', data);
    }

    getStocks(): Observable<StockModel[]> {
        return this.http.get<StockModel[]>(environment.baseApiUrl + 'trading/stocks');
    }

    getTradingHistory(data?: any): Observable<any> {
        const pageSize = 10;
        const page = 1;
        const dateTo = new Date();
        return this.http.get(environment.baseApiUrl + 'trading/history', {
            params: {
                pageSize: data?.pageSize || pageSize,
                page: data?.page || page,
                tickers: data?.tickers || [],
                dateTo: data?.dateTo || dateTo.toISOString(),
                dateFrom: data?.dateFrom || '2023-01-01T00:00:00.000Z',
            },
        });
    }

    getTickers(): Observable<any> {
        return this.http.get(environment.baseApiUrl + 'trading/tickers');
    }

    getActiveTrades(): Observable<any> {
        return this.http.get(environment.baseApiUrl + 'trading/active');
    }

    updateUserSession(sessionId: string, sessionUpdate: Partial<UserSession>): Observable<Partial<UserSession>> {
        return this.http.put(environment.baseApiUrl + `trading/session/${sessionId}`, sessionUpdate);
    }
}
