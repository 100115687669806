import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-storage.service';

const TOKEN_HEADER_KEY = 'Authorization';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        readonly tokenStorage: TokenStorageService,
        readonly router: Router,
        readonly authService: AuthService
    ) {}

    isRefreshing = false;
    private readonly refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
        let authReq = req;
        const token = this.tokenStorage.getToken();
        if (token !== null) {
            authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
        }
        return next.handle(authReq).pipe(
            catchError((error) => {
                if (
                    error instanceof HttpErrorResponse &&
                    !authReq.url.includes('auth/log-in') &&
                    error.status === 401
                ) {
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    if (error.url?.includes('auth/refresh-token')) {
                        this.isRefreshing = false;
                        this.authService.logout();
                        this.tokenStorage.signOut();
                        void this.router.navigate(['auth']).then(() => {});
                    }
                    return this.handle401Error(authReq, next);
                }
                return throwError(() => error);
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            const token = this.tokenStorage.getRefreshToken();
            if (token !== null) {
                return this.authService.refreshToken(token).pipe(
                    switchMap((token: any) => {
                        this.isRefreshing = false;
                        this.tokenStorage.saveToken(token.access_token);
                        this.refreshTokenSubject.next(token.access_token);
                        return next.handle(this.addTokenHeader(request, token.access_token));
                    }),
                    catchError((err) => {
                        if (err.status === 404) {
                            return throwError(() => err);
                        }
                        this.isRefreshing = false;
                        this.authService.logout();
                        this.tokenStorage.signOut();
                        void this.router.navigate(['auth']).then(() => {});
                        return throwError(() => err);
                    })
                );
            }
        }
        return this.refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }
}
