<div class="table-content">
    <mat-list>
        <mat-list-item> {{ 'activeTrades.elements.activeTrades' | translate }} </mat-list-item>
    </mat-list>
    <div [ngClass]="{ 'table-container-mobile': innerWidth <= 820, 'table-container': innerWidth > 820 }">
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef class="table-column">#</mat-header-cell>
                <mat-cell *matCellDef="let i = index" class="table-column">{{ i + 1 }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'activeTrades.table.date' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column">{{
                    row.time | date : 'dd.MM.yyyy' : 'en-US'
                }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="time">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'activeTrades.table.time' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column">{{
                    row.date | date : 'HH:mm:ss' : 'en-US'
                }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="ticker">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'activeTrades.table.ticker' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column">{{ row.ticker }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'activeTrades.table.quantity' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column">{{ row.quantity }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="tradeSize">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'activeTrades.table.tradeSize' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column"
                    >${{ row.tradeSize | number : '1.0-2' : 'fr' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="strategy">
                <mat-header-cell *matHeaderCellDef class="table-column" class="indicator-value-column">{{
                    'activeTrades.table.strategy' | translate
                }}</mat-header-cell>
                <mat-cell
                    *matCellDef="let row"
                    class="table-column"
                    class="indicator-value-column"
                    title="{{ row.strategy }}"
                    ><span class="ellipsis">{{ row.strategy }}</span></mat-cell
                >
            </ng-container>
            <ng-container matColumnDef="indicatorValue">
                <mat-header-cell *matHeaderCellDef class="table-column" class="indicator-value-column">{{
                    'activeTrades.table.indicatorValue' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column" class="indicator-value-column"
                    ><span>{{ row.indicatorValue === null ? '-' : row.indicatorValue }}</span></mat-cell
                >
            </ng-container>
            <ng-container matColumnDef="executionTime">
                <mat-header-cell *matHeaderCellDef class="table-column" class="indicator-value-column">{{
                    'activeTrades.table.executionTime' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column" class="indicator-value-column"
                    ><span>{{ row.executionTime | date : 'dd.MM.YYYY HH:mm:ss' : 'en-US' }}</span></mat-cell
                >
            </ng-container>
            <ng-container matColumnDef="closePrice">
                <mat-header-cell *matHeaderCellDef class="table-column" class="indicator-value-column">{{
                    'activeTrades.table.closePrice' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column" class="indicator-value-column"
                    ><span>{{ row.closePrice === null ? '-' : row.closePrice }}</span>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"> </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <div *ngIf="!this.data || this.data?.length === 0" class="empty-error">
            {{ 'activeTrades.table.error' | translate }}
        </div>
    </div>
</div>
