import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class StatusUpdateService {
    private readonly statusUpdateSubject: Subject<void> = new Subject<void>();

    statusUpdate$ = this.statusUpdateSubject.asObservable();

    triggerStatusUpdate(): void {
        this.statusUpdateSubject.next();
    }
}
