<div class="form-container">
    <mat-card>
        <mat-card-title>{{ 'auth.login' | translate }}</mat-card-title>
        <mat-card-content>
            <form [formGroup]="form" (ngSubmit)="submit()">
                <p>
                    <mat-form-field class="form-field">
                        <input type="text" matInput placeholder="Username" formControlName="username" />
                        <mat-error *ngIf="username?.errors?.['required']">
                            {{ 'auth.error.emailRequired' | translate }}
                        </mat-error>
                        <mat-error *ngIf="username?.errors?.['email']">
                            {{ 'auth.error.invalidEmail' | translate }}
                        </mat-error>
                    </mat-form-field>
                </p>

                <p>
                    <mat-form-field class="form-field">
                        <input type="password" matInput placeholder="Password" formControlName="password" />
                        <mat-error *ngIf="password?.errors?.['passwordStrength']">
                            {{ 'auth.error.invalidPassword' | translate }}
                        </mat-error>
                    </mat-form-field>
                </p>
                <mat-error *ngIf="this.error" class="error-message">
                    {{ 'auth.error.invalidCredentials' | translate }}
                </mat-error>
                <div class="button">
                    <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid">
                        {{ 'auth.login' | translate }}
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
