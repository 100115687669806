<app-header></app-header>
<div class="page-container">
    <div [ngClass]="{ 'stocks-container-mobile': innerWidth <= 820, 'stocks-container': innerWidth > 820 }">
        <div>
            <app-status></app-status>
            <label
                class="label"
                background-color="primary"
                *ngIf="this.isUploadAvailable"
                [ngStyle]="{ height: innerWidth <= 1205 ? '34px' : '28px' }"
            >
                <input
                    type="file"
                    name="file"
                    id="file"
                    class="inputfile"
                    accept=".csv"
                    (change)="uploadFile($event)"
                />
                <div class="upload-text">
                    <mat-icon>file_upload</mat-icon>
                    <span>{{ 'stocks.elements.uploadButton' | translate }}</span>
                </div>
            </label>
            <div>
                <button mat-raised-button color="primary" (click)="openTradeHistory()" class="trade-history-button">
                    {{ 'activeTrades.elements.tradeHistoryButton' | translate }}
                </button>
                <button mat-raised-button color="primary" class="trade-history-button">
                    <a download="template.csv" type="text/csv" href="/assets/template/template.csv" class="download"
                        >Download CSV template</a
                    >
                </button>
            </div>
        </div>
        <app-stocks (status)="statusUpdate($event)"></app-stocks>
    </div>
    <app-trades></app-trades>
</div>
