import {
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderComponent } from '../header/header.component';
import { StatusComponent } from '../status/status.component';
import { StocksComponent } from '../stocks/stocks.component';
import { ActiveTradesComponent } from '../trades/active-trades/active-trades.component';
import { TradesHistoryComponent } from '../trades/trades-history/trades-history.component';
import { TradesComponent } from '../trades/trades.component';
import { DashboardComponent } from './dashboard.component';
import { StatusUpdateModule } from 'src/app/modules/status-update.service';

@NgModule({
    declarations: [
        DashboardComponent,
        ActiveTradesComponent,
        TradesComponent,
        StocksComponent,
        HeaderComponent,
        StatusComponent,
        TradesHistoryComponent,
    ],
    imports: [
        TranslateModule,
        CommonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatButtonModule,
        MatListModule,
        MatTableModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgxMatDatetimePickerModule,
        MatDatepickerModule,
        NgxMatTimepickerModule,
        MatNativeDateModule,
        NgxMatNativeDateModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatSortModule,
        MatSlideToggleModule,
        StatusUpdateModule,
    ],
})
export class DashboardModule {}
