import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.dev';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(readonly http: HttpClient) {}
    login(username: string, password: string): Observable<any> {
        return this.http.post(
            environment.baseApiUrl + 'auth/log-in',
            {
                email: username,
                password,
            },
            httpOptions
        );
    }

    logout(): Observable<any> {
        return this.http.post(environment.baseApiUrl + 'auth/log-out', '');
    }

    refreshToken(token: string): any {
        return this.http.post(
            environment.baseApiUrl + 'auth/refresh-token',
            {
                refresh_token: token,
            },
            httpOptions
        );
    }
}
