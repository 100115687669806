<app-header></app-header>
<div class="table-content">
    <mat-list>
        <mat-list-item>{{ 'historyTrades.elements.tradeHistory' | translate }}</mat-list-item>
    </mat-list>
    <div [ngClass]="{ 'filters-container': this.innerWidth > 820, 'filters-container-mobile': innerWidth <= 820 }">
        <div class="autocomplete-container">
            <span>{{ 'historyTrades.elements.selectTicker' | translate }}</span>
            <form class="chip-list-form">
                <mat-form-field class="chip-list" appearance="fill">
                    <input
                        placeholder="Select ticker"
                        #tickerInput
                        [formControl]="tickerCtrl"
                        [matChipInputFor]="chipGrid"
                        [matAutocomplete]="auto"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event)"
                        [required]="this.tickers.length > 0"
                    />
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let ticker of filteredTickers | async" [value]="ticker">
                            {{ ticker }}
                        </mat-option>
                    </mat-autocomplete>
                    <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearForm('ticker')">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-chip-grid #chipGrid aria-label="Ticker selection">
                        <mat-chip-row *ngFor="let ticker of tickers" (removed)="remove(ticker)">
                            {{ ticker }}
                            <button matChipRemove [attr.aria-label]="'remove ' + ticker">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>
                </mat-form-field>
            </form>
        </div>
        <div class="datepicker-container">
            <span>{{ 'historyTrades.elements.selectFrom' | translate }}</span>
            <mat-form-field class="datepicker" (click)="fromPicker.open()">
                <input
                    matInput
                    [ngxMatDatetimePicker]="fromPicker"
                    [max]="maxDate()"
                    placeholder="Choose a date"
                    [formControl]="fromDateControl"
                    [disabled]="true"
                    readonly
                />
                <mat-datepicker-toggle matSuffix [for]="$any(fromPicker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #fromPicker> </ngx-mat-datetime-picker>
                <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearForm('fromDate')">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <div class="empty-error">
                {{ errorMessage }}
            </div>
        </div>
        <span class="dash" *ngIf="this.innerWidth > 820"></span>
        <div class="datepicker-container">
            <span>{{ 'historyTrades.elements.selectTo' | translate }}</span>
            <mat-form-field class="datepicker" (click)="toPicker.open()">
                <input
                    matInput
                    [ngxMatDatetimePicker]="toPicker"
                    [max]="maxDate()"
                    placeholder="Choose a date"
                    [formControl]="toDateControl"
                    readonly
                    required
                />
                <mat-datepicker-toggle matSuffix [for]="$any(toPicker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #toPicker [touchUi]="false"> </ngx-mat-datetime-picker>
                <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearForm('toDate')">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <button
            mat-raised-button
            color="primary"
            (click)="getTrades(0)"
            class="apply-button"
            [disabled]="!isFormValid()"
        >
            {{ 'historyTrades.elements.applyButton' | translate }}
        </button>
    </div>
    <div [ngClass]="{ 'table-container': innerWidth > 820, 'table-container-mobile': innerWidth <= 820 }">
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef class="table-column">#</mat-header-cell>
                <mat-cell *matCellDef="let i = index" class="table-column">{{
                    i + 1 + (pageIndex ? pageIndex * pageSize : 0)
                }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'historyTrades.table.date' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column">{{
                    row.date | date : 'dd.MM.yyyy' : 'en-US'
                }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="time">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'historyTrades.table.time' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column">{{
                    row.time | date : 'HH:mm:ss' : 'en-US'
                }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="ticker">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'historyTrades.table.ticker' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column">{{ row.ticker }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'historyTrades.table.quantity' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column">{{ row.quantity }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="tradeSize">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'historyTrades.table.tradeSize' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column"
                    >${{ row.tradeSize | number : '1.0-2' : 'fr' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="direction">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'historyTrades.table.direction' | translate
                }}</mat-header-cell>
                <mat-cell
                    *matCellDef="let row"
                    class="table-column"
                    [ngClass]="row.direction === 'sell' ? 'sell' : 'buy'"
                    >{{ row.direction.charAt(0).toUpperCase() + row.direction.slice(1) }}</mat-cell
                >
            </ng-container>
            <ng-container matColumnDef="strategy">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'historyTrades.table.strategy' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column" title="{{ row.strategy }}">
                    <span class="ellipsis">{{ row.strategy }}</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="indicatorValue">
                <mat-header-cell *matHeaderCellDef class="table-column" class="indicator-value-column">{{
                    'historyTrades.table.indicatorValue' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column" class="indicator-value-column"
                    ><span>{{ row.indicatorValue === null ? '-' : row.indicatorValue }}</span></mat-cell
                >
            </ng-container>
            <ng-container matColumnDef="executionTime">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'historyTrades.table.executionTime' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column"
                    ><span>{{
                        row.executionTime
                            | date
                                : 'dd.MM.YYYY
                        HH:mm:ss'
                                : 'en-US'
                    }}</span></mat-cell
                >
            </ng-container>
            <ng-container matColumnDef="closePrice">
                <mat-header-cell *matHeaderCellDef class="table-column">{{
                    'historyTrades.table.closePrice' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column"
                    ><span>{{ row.closePrice === null ? '-' : row.closePrice }}</span></mat-cell
                >
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"> </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
    </div>
    <div class="pagination-container">
        <div class="custom-pagination-container">
            <span class="total-pages">{{ 'historyTrades.elements.pageCounter' | translate }} {{ totalPages }}</span>

            <input
                class="page-input"
                type="number"
                min="0"
                [max]="this.totalPages"
                matInput
                placeholder="Enter page"
                [(ngModel)]="pageNumber"
                [formControl]="pageControl"
                (ngModelChange)="this.pageUpdate.next($event)"
            />
        </div>
        <mat-paginator
            [pageSizeOptions]="[10, 20, 30]"
            [length]="totalLength"
            [pageIndex]="pageIndex"
            showFirstLastButtons
            (page)="getPageData($event)"
            class="paginator-container"
        >
        </mat-paginator>
    </div>
    <div *ngIf="!this.data || this.data?.length === 0" class="empty-error">
        {{ 'historyTrades.table.error' | translate }}
    </div>

    <button mat-raised-button color="primary" class="dashboard-button" (click)="navigateToDashboard()">
        {{ 'historyTrades.elements.dashboardButton' | translate }}
    </button>
</div>
