import { ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    constructor(
        readonly authService: AuthService,
        readonly tokenStorage: TokenStorageService,
        readonly router: Router,
        readonly ref: ChangeDetectorRef,
        public readonly translate: TranslateService
    ) {
        translate.addLangs(['en']);
        translate.setDefaultLang('en');
    }

    subscription: any;
    form: FormGroup = new FormGroup({
        username: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [this.passwordValidator()]),
    });

    error: boolean = false;
    unsubscribe = new Subject();

    public submit(): void {
        if (this.form.valid) {
            this.error = false;
            this.authService
                .login(this.form.get('username')?.value, this.form.get('password')?.value)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    (data) => {
                        this.tokenStorage.saveUserName(this.form.get('username')?.value);
                        this.tokenStorage.saveToken(data.access_token);
                        this.tokenStorage.saveRefreshToken(data.refresh_token);
                        void this.router.navigate(['dashboard']).then(() => {});
                    },
                    (error) => {
                        error.status === 401 ? (this.error = true) : (this.error = false);
                    }
                );
        }
    }

    public passwordValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            const passwordValid = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value);
            return !passwordValid ? { passwordStrength: true } : null;
        };
    }

    get username(): AbstractControl<any, any> | null {
        return this.form.get('username');
    }

    get password(): AbstractControl<any, any> | null {
        return this.form.get('password');
    }

    ngOnDestroy(): void {
        this.unsubscribe.next('');
    }
}
