import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { TradesHistoryComponent } from './components/trades/trades-history/trades-history.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardResolver } from './resolvers/dashboard.resolver';
import { HistoryResolver } from './resolvers/history.resolver';

const routes: Routes = [
    { path: '', redirectTo: '/auth', pathMatch: 'full' },
    {
        path: 'auth',
        component: LoginComponent,
        // eslint-disable-next-line @typescript-eslint/promise-function-async
        loadChildren: () => import('./components/login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        resolve: {
            dashboardResolver: DashboardResolver,
        },
        children: [
            {
                path: '',
                // eslint-disable-next-line @typescript-eslint/promise-function-async
                loadChildren: () => import('./components/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
        ],
    },
    {
        path: 'trades-history',
        component: TradesHistoryComponent,
        canActivate: [AuthGuard],
        resolve: {
            historyResolver: HistoryResolver,
        },
    },
    { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [DashboardResolver, HistoryResolver],
})
export class AppRoutingModule {}
