/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { TradingService } from 'src/app/services/trading.service';

export class Ticker {
    constructor(public tickerName: string, public selected?: boolean) {
        if (selected === undefined) selected = false;
    }
}

export interface Order {
    date: string;
    time: string;
    ticker: string;
    quantity: string;
    tradeSize: string;
    direction: string;
    indicatorValue: string;
}
@Component({
    selector: 'app-trades-history',
    templateUrl: 'trades-history.component.html',
    styleUrls: ['trades-history.component.scss'],
})
export class TradesHistoryComponent implements OnInit, OnDestroy {
    separatorKeysCodes: number[] = [ENTER, COMMA];
    fromDateControl = new FormControl();
    toDateControl = new FormControl();
    dataSource: any;
    tickerCtrl = new FormControl('');
    filteredTickers: Observable<string[]>;
    unsubscribe = new Subject<void>();
    tickers: string[] = [];
    allTickers: string[] = [];
    errorMessage: string = '';
    pageSize: number;
    pageIndex: number;
    totalLength: any;
    data: any;
    displayedColumns = [
        'id',
        'date',
        'time',
        'ticker',
        'quantity',
        'tradeSize',
        'direction',
        'indicatorValue',
        'strategy',
        'executionTime',
        'closePrice',
    ];

    pageNumber: any;
    pageControl: any;
    totalPages: any;
    max: number;
    innerWidth: any;
    pageUpdate = new Subject<string>();
    param: any;

    @ViewChild('tickerInput') tickerInput: ElementRef<HTMLInputElement>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    @HostListener('window:resize', ['$event']) onResize(event: any): void {
        this.innerWidth = window.innerWidth;
    }

    constructor(
        public tradingService: TradingService,
        private readonly actRoute: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        readonly router: Router,
        private readonly adapter: NgxMatDateAdapter<Date>
    ) {
        adapter.setLocale('en-GB');
    }

    ngOnInit(): void {
        this.innerWidth = window.innerWidth;
        this.toDateControl.setValue(new Date());
        const data = this.actRoute.snapshot.data['historyResolver'];
        data.tickers.pipe(takeUntil(this.unsubscribe)).subscribe((tickers: any) => {
            this.allTickers = tickers.map((x: any) => {
                return x.tickerName;
            });
            this.filteredTickers = this.tickerCtrl.valueChanges.pipe(
                startWith(null),
                map((ticker: string | null) => (ticker !== null ? this._filter(ticker) : this.allTickers.slice()))
            );
            this.ref.detectChanges();
        });
        data.historyTrades.pipe(takeUntil(this.unsubscribe)).subscribe((trades: any) => {
            this.data = trades.data.map((el: any) => {
                return {
                    date: new Date(el.createDateTime),
                    time: new Date(el.createDateTime),
                    ticker: el.tickerName,
                    quantity: el.quantity || 0,
                    tradeSize: el.tradeSize || 0,
                    direction: el.direction,
                    indicatorValue: el.indicatorValue || 0,
                };
            });
            this.dataSource = new MatTableDataSource<Order>(this.data);
            this.totalLength = trades.count;
            this.totalPages = Math.ceil(this.totalLength / this.paginator?.pageSize);
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            this.param = { value: `${this.totalPages}` };
            this.ref.detectChanges();
        });
        this.pageControl = new FormControl('', [Validators.max(this.totalPages), Validators.min(0)]);
        this.pageUpdate.pipe(debounceTime(700), distinctUntilChanged()).subscribe((value) => {
            if (!(this.pageNumber > this.totalPages)) {
                this.paginator.page.next({
                    pageIndex: this.pageNumber - 1,
                    pageSize: this.pageSize,
                    length: this.totalLength,
                });
            }
        });
    }

    add(event: MatChipInputEvent): void {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const value = (event.value || '').trim();
        if (value.length > 0) {
            if (!this.tickers.includes(value)) {
                this.tickers.push(value);
            }
        }
        event.chipInput.clear();

        this.tickerCtrl.setValue(null);
    }

    clearForm(formName: string): void {
        if (formName === 'ticker') {
            this.tickers = [];
            this.tickerCtrl.setValue(null);
        }
        if (formName === 'toDate') {
            this.toDateControl.setValue(new Date());
        }
        if (formName === 'fromDate') {
            this.fromDateControl.setValue('');
        }
        this.ref.detectChanges();
    }

    remove(ticker: string): void {
        const index = this.tickers.indexOf(ticker);

        if (index >= 0) {
            this.tickers.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (!this.tickers.includes(event.option.viewValue)) {
            this.tickers.push(event.option.viewValue);
            this.tickerInput.nativeElement.value = '';
            this.tickerCtrl.setValue(null);
        } else {
            this.remove(event.option.viewValue);
        }
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        if (filterValue !== 'all') {
            return this.allTickers.filter((ticker) => ticker.toLowerCase().includes(filterValue));
        } else {
            return this.allTickers;
        }
    }

    getTrades(pageIndex: number): void {
        const toDate = this.toDateControl?.value;
        const fromDate = this.fromDateControl?.value;
        const data = {
            dateTo: toDate ? toDate.toISOString() : null,
            tickers: this.tickers.toString(),
            dateFrom: fromDate ? fromDate.toISOString() : null,
            pageSize: this.pageSize,
            page: pageIndex + 1,
        };
        this.pageIndex = pageIndex;
        this.ref.detectChanges();
        this.tradingService
            .getTradingHistory(data)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((responce) => {
                this.data = responce.data.map((el: any) => {
                    return {
                        date: new Date(el.createDateTime),
                        time: new Date(el.createDateTime),
                        ticker: el.tickerName,
                        quantity: el.quantity || 0,
                        tradeSize: el.tradeSize || 0,
                        direction: el.direction,
                        indicatorValue: el.indicatorValue,
                        executionTime: new Date(el.executionTime),
                        strategy: el.strategy,
                        closePrice: el.closePrice,
                    };
                });
                this.dataSource = new MatTableDataSource<Order>(this.data);
                this.totalLength = responce.count;
                this.totalPages = Math.ceil(this.totalLength / this.paginator?.pageSize);
                this.ref.detectChanges();
            });
    }

    getPageData($event: PageEvent): void {
        this.pageSize = $event.pageSize;
        const pageIndex = $event.pageIndex;
        this.getTrades(pageIndex);
    }

    isFormValid(): boolean {
        if (this.fromDateControl?.value > this.toDateControl?.value) {
            this.errorMessage = 'This time range is invalid. Please, select the valid one.';
        } else {
            this.errorMessage = '';
        }
        return this.toDateControl.valid && !(this.fromDateControl.value > this.toDateControl.value);
    }

    navigateToDashboard(): void {
        void this.router.navigate(['dashboard']).then(() => {});
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
    }

    maxDate(): Date {
        return new Date();
    }
}
