import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Subscription, finalize, switchMap, takeUntil } from 'rxjs';
import { UserSession } from 'src/app/model/user-session.model';
import { StatusUpdateService } from 'src/app/modules/status-update.module';
import { ActiveTradesService } from 'src/app/services/active-trades.service';
import { StatusService } from 'src/app/services/status.service';
import { UserSessionService } from 'src/app/services/trade-session.service';
import { TradingService } from 'src/app/services/trading.service';

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit, OnDestroy {
    constructor(
        private readonly statusService: StatusService,
        private readonly ref: ChangeDetectorRef,
        private readonly activeTradesService: ActiveTradesService,
        private readonly userSessionService: UserSessionService,
        private readonly statusUpdateService: StatusUpdateService,
        private readonly tradingService: TradingService
    ) {}

    marketStatus: any = null;
    userStatus: any;
    balanceStatus: any;
    trades: number;
    userSessionsSubject: Subject<UserSession[]> = new Subject<UserSession[]>();
    userSessionsSubscription: Subscription;
    marketSellStatus = new FormControl();
    private statusUpdateSubscription: Subscription;

    private readonly unsubscribe: Subject<void> = new Subject();
    ngOnInit(): void {
        this.statusService
            .getMarketStatuses()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((x) => {
                this.marketStatus = x;
                if (this.marketStatus.startDayBalance === null || this.marketStatus === null) {
                    this.statusService
                        .getBalanceStatus()
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((x) => {
                            this.marketStatus.startDayBalance = x;
                            this.ref.detectChanges();
                        });
                }
                if (this.marketStatus === null) {
                    this.statusService
                        .getUserStatus()
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((x) => {
                            this.marketStatus = x;
                        });
                }
            });

        this.activeTradesService
            .getTrades()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((x) => {
                this.trades = x.dailyCount;
                this.statusUpdateService.triggerStatusUpdate();
            });

        this.userSessionsSubscription = this.userSessionService.getUserSessions(2).subscribe((sessions) => {
            this.userSessionsSubject.next(sessions);
            this.setCurrentSessionSellStatus(sessions);
        });
        this.statusUpdateSubscription = this.statusUpdateService.statusUpdate$
            .pipe(switchMap(() => this.userSessionService.getUserSessions(2)))
            .subscribe((sessions) => {
                this.userSessionsSubject.next(sessions);
                this.setCurrentSessionSellStatus(sessions);
            });

        this.ref.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.statusUpdateSubscription.unsubscribe();
        this.userSessionsSubscription.unsubscribe();
    }

    isCurrentDate(createDateTime: string): boolean {
        const currentDate = new Date();
        const sessionDate = new Date(createDateTime);

        // Compare date values without time
        return sessionDate.toDateString() === currentDate.toDateString();
    }

    onSwitchToggle(sessionId: string, status: boolean) {
        const sub = this.tradingService
            .updateUserSession(sessionId, { isSellOnClose: status })
            .pipe(
                finalize(() => {
                    sub.unsubscribe();
                })
            )
            .subscribe({
                next: (value) => {
                    value?.isSellOnClose && this.marketSellStatus.setValue(value.isSellOnClose);
                    sub.unsubscribe();
                },
            });
    }

    setCurrentSessionSellStatus(sessions: UserSession[]) {
        const curSession = sessions.find((val) => this.isCurrentDate(val.createDateTime));
        if (curSession) this.marketSellStatus.setValue(curSession.isSellOnClose);
    }
}
