import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';

import { TradingService } from '../services/trading.service';

@Injectable()
export class HistoryResolver implements Resolve<any> {
    constructor(public tradingService: TradingService) {}

    resolve(): Observable<any> {
        const tickers = this.tradingService.getTickers();
        const historyTrades = this.tradingService.getTradingHistory();
        const data = { tickers, historyTrades };
        return of(data);
    }
}
