import { Component, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StocksComponent } from '../stocks/stocks.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
    constructor(readonly router: Router) {}
    innerWidth: any;
    isUploadAvailable: boolean = false;

    @HostListener('window:resize', ['$event']) onResize(event: any): void {
        this.innerWidth = window.innerWidth;
    }

    @ViewChild(StocksComponent) stocks: StocksComponent;

    ngOnInit(): void {
        this.innerWidth = window.innerWidth;
    }

    statusUpdate(event: boolean): void {
        this.isUploadAvailable = event;
    }

    public uploadFile(event: any): void {
        this.stocks.uploadFile(event);
    }

    public openTradeHistory(): void {
        void this.router.navigate(['trades-history']).then(() => {});
    }
}
