import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.dev';

@Injectable({ providedIn: 'root' })
export class FileUploadService {
    constructor(readonly http: HttpClient) {}
    uploadCsv(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(environment.baseApiUrl + 'dashboard/upload-csv', formData);
    }
}
