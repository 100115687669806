/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ActiveTradesService } from 'src/app/services/active-trades.service';

export interface Order {
    date: string;
    time: string;
    ticker: string;
    quantity: string;
    tradeSize: string;
    indicatorValue: string;
}
@Component({
    selector: 'app-active-trades',
    templateUrl: './active-trades.component.html',
    styleUrls: ['./active-trades.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveTradesComponent implements OnInit {
    constructor(
        readonly router: Router,
        readonly activeTradesService: ActiveTradesService,
        private readonly ref: ChangeDetectorRef
    ) {}

    unsubscribe = new Subject<void>();
    displayedColumns: string[] = [
        'id',
        'date',
        'time',
        'ticker',
        'quantity',
        'tradeSize',
        'indicatorValue',
        'strategy',
        'closePrice',
        'executionTime',
    ];

    dataSource: any;
    data: any;
    innerWidth: any;

    @HostListener('window:resize', ['$event']) onResize(event: any): void {
        this.innerWidth = window.innerWidth;
    }

    @HostListener('document:visibilitychange', ['$event'])
    visibilitychange(): void {
        !document.hidden && this.activeTradesService.emitTrades();
    }

    ngOnInit(): void {
        this.innerWidth = window.innerWidth;
        this.activeTradesService.emitTrades();
        this.activeTradesService
            .getTrades()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (x) => {
                    this.setData(x);
                },
                (error) => {
                    console.log(error);
                    return error;
                }
            );
    }

    public openTradeHistory(): void {
        void this.router.navigate(['trades-history']).then(() => {});
    }

    setData(x: any): void {
        let data;
        x.currentOrders ? (data = x.currentOrders) : (data = x);
        this.data = data.map((el: any) => {
            return {
                date: new Date(el.createDateTime),
                time: new Date(el.createDateTime),
                ticker: el.tickerName,
                quantity: el.quantity || 0,
                tradeSize: el.tradeSize || 0,
                indicatorValue: el.indicatorValue,
                direction: el.direction,
                executionTime: new Date(el.executionTime),
                strategy: el.strategy,
                closePrice: el.closePrice,
            };
        });
        this.data = this.data.sort((a: any, b: any) => (b.time > a.time ? 1 : a.time > b.time ? -1 : 0));
        this.dataSource = new MatTableDataSource<Order>(this.data);
        this.ref.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
    }
}
