/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { StatusUpdateService } from 'src/app/modules/status-update.module';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { StatusService } from 'src/app/services/status.service';
import { StocksUpdateService } from 'src/app/services/stocks-update.service';
import { TradingService } from 'src/app/services/trading.service';

export interface CsvData {
    ticker: string;
    quantity: number;
}
@Component({
    selector: 'app-stocks',
    templateUrl: './stocks.component.html',
    styleUrls: ['./stocks.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StocksComponent implements OnDestroy, OnInit {
    constructor(
        private readonly tradingService: TradingService,
        private readonly fileUploadService: FileUploadService,
        private readonly ref: ChangeDetectorRef,
        private readonly stocksUpdateService: StocksUpdateService,
        private readonly actRoute: ActivatedRoute,
        private readonly statusService: StatusService,
        private readonly router: Router,
        private readonly statusUpdateService: StatusUpdateService
    ) {}

    displayedColumns: string[] = ['ticker', 'strategy', 'quantity', 'actions'];
    dataSource: any;
    data: any;
    innerWidth: any;
    unsubscribe = new Subject<void>();
    errorMessage: string = 'No Valid Data Available, please upload new CSV';
    isSessionExists: boolean = false;
    stocks$: Observable<any>;
    marketStatus: boolean = false;

    @Output()
    status: EventEmitter<boolean> = new EventEmitter<boolean>();

    @HostListener('window:resize', ['$event']) onResize(event: any): void {
        this.innerWidth = window.innerWidth;
    }

    ngOnInit(): void {
        this.innerWidth = window.innerWidth;
        const data = this.actRoute.snapshot.data['dashboardResolver'];
        this.statusService
            .getMarketStatuses()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((x) => {
                this.marketStatus = x.marketStatus;
                this.status.emit(!this.isSessionExists && !this.marketStatus);
            });
        data.stocks.pipe(takeUntil(this.unsubscribe)).subscribe(
            (responce: any) => {
                this.isSessionExists = true;
                this.status.emit(!this.isSessionExists && !this.marketStatus);
                if (!responce) {
                    return;
                }
                this.data = responce.map((x: any) => {
                    return {
                        ticker: x.ticker,
                        strategy: x.strategy,
                        quantity: x.quantity,
                        actions: x.isActive,
                        id: x.id,
                    };
                });
                this.dataSource = new MatTableDataSource<CsvData>(this.data);
                this.ref.detectChanges();
            },
            (error: any) => {
                return error;
            }
        );
    }

    public uploadFile(event: Event): void {
        const target = event.target as HTMLInputElement;
        const files = target.files as FileList;
        this.errorMessage = '';
        this.fileUploadService.uploadCsv(files[0]).subscribe(
            (data) => {
                if (!data[0]) {
                    this.errorMessage =
                        'There are no stocks with valid data inside the file. Please, try to upload a new one.';
                    target.value = '';
                    this.ref.detectChanges();
                    return;
                }
                this.data = data;
                this.dataSource = new MatTableDataSource<CsvData>(data);
                this.ref.detectChanges();
                target.value = '';
            },
            (error) => {
                this.data = [];
                this.dataSource = new MatTableDataSource<CsvData>(this.data);
                this.errorMessage = error.error.error;
                this.ref.detectChanges();
                target.value = '';
            }
        );
    }

    removeRow(id: number): void {
        this.data.splice(id, 1);
        this.dataSource = new MatTableDataSource<CsvData>(this.data);
        this.ref.detectChanges();
    }

    uploadFinalData(): void {
        const data = this.data.map((x: object) => {
            return Object.fromEntries(Object.entries(x).map(([k, v]) => [k.toLowerCase(), v]));
        });
        this.tradingService.uploadFinalData(data).subscribe((x) => {
            this.tradingService
                .getStocks()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    (data) => {
                        this.isSessionExists = true;
                        this.status.emit(!this.isSessionExists && !this.marketStatus);
                        this.data = data.map((x) => {
                            return {
                                ticker: x.ticker,
                                strategy: x.strategy,
                                quantity: x.quantity,
                                actions: x.isActive,
                            };
                        });
                        this.dataSource = new MatTableDataSource<CsvData>(this.data);
                        this.ref.detectChanges();
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        this.router.navigate(['dashboard']);
                        this.statusUpdateService.triggerStatusUpdate();
                    },
                    (error) => {
                        return error;
                    }
                );
        });
    }

    updateStock(index: number, id: string): void {
        this.data[index].actions = false;
        this.ref.detectChanges();
        this.stocksUpdateService.updateStock(id, false).pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
    }
}
