import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { firstValueFrom, map, Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { TokenStorageService } from './token-storage.service';

@Injectable({
    providedIn: 'root',
})
export class ActiveTradesService {
    constructor(
        private readonly socket: Socket,
        private readonly tokenStorage: TokenStorageService,
        private readonly authService: AuthService
    ) {
        this.socket.on('exception', async () => {
            const token = this.tokenStorage.getRefreshToken();
            if (token !== null) {
                await firstValueFrom(
                    this.authService.refreshToken(token).pipe(
                        map((token: any) => {
                            this.tokenStorage.saveToken(token.access_token);
                            this.emitTrades();
                        })
                    )
                );
            }
        });
        this.socket.on('connect', () => {
            this.emitTrades();
        });
    }

    emitTrades(): void {
        this.socket.emit('subscribe', {
            token: this.tokenStorage.getToken(),
            subscriptions: ['active_trades', 'market_status'],
        });
    }

    getTrades(): Observable<any> {
        return this.socket.fromEvent('active_trades').pipe(map((data) => data as any));
    }
}
