import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';
import { Observable } from 'rxjs';
import { UserSession } from '../model/user-session.model';

@Injectable({ providedIn: 'root' })
export class UserSessionService {
    constructor(readonly http: HttpClient) {}

    getUserSessions(take: number): Observable<UserSession[]> {
        return this.http.get<UserSession[]>(environment.baseApiUrl + 'trading/session', { params: { take } });
    }
}
