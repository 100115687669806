<mat-list>
    <mat-list-item>
        {{ 'status.marketStatus' | translate }}
        {{ this.marketStatus?.marketStatus ? 'Opened' : 'Closed' }}</mat-list-item
    >
    <mat-list *ngIf="this.marketStatus?.marketStatus">
        <mat-list-item>
            {{ 'status.startOfDayBalance' | translate }} ${{
                this.marketStatus?.startDayBalance || 0 | number : '1.0-2' : 'fr'
            }}</mat-list-item
        >
        <mat-list-item> {{ 'status.trades' | translate }} {{ this.trades || 0 }}</mat-list-item>
        <mat-list-item>
            {{ 'status.currentBalance' | translate }} ${{
                this.marketStatus?.currentBalance || 0 | number : '1.0-2' : 'fr'
            }}</mat-list-item
        >
    </mat-list>
    <mat-list *ngIf="!this.marketStatus?.marketStatus">
        <mat-list-item>
            {{ 'status.startOfDayBalance' | translate }} ${{
                this.marketStatus?.startDayBalance || 0 | number : '1.0-2' : 'fr'
            }}
        </mat-list-item>
        <mat-list-item> {{ 'status.trades' | translate }} -</mat-list-item>
        <mat-list-item> {{ 'status.currentBalance' | translate }} -</mat-list-item>
    </mat-list>
    <mat-list-item *ngFor="let session of userSessionsSubject | async">
        {{ session.createDateTime | date : 'dd.MM.yyyy' : 'en-US' }}
        {{ 'status.pNl' | translate }}
        <ng-container *ngIf="isCurrentDate(session.createDateTime) as currentDate; else notCurrentDate">
            <span>
                (current)
                {{
                    this.marketStatus?.marketStatus
                        ? (this.marketStatus?.pNl || 0 | number : '1.0-2' : 'fr')
                        : session.pNl !== null
                        ? (session.pNl || 0 | number : '1.0-2' : 'fr')
                        : '-'
                }}
            </span>
            <mat-slide-toggle
                class="sell-toggle"
                color="primary"
                [formControl]="marketSellStatus"
                (change)="onSwitchToggle(session.id, marketSellStatus.value)"
                >{{ marketSellStatus.value ? 'Sell' : 'Keep' }}
            </mat-slide-toggle>
        </ng-container>
        <ng-template #notCurrentDate>
            <span>
                {{ session.pNl != null ? (session.pNl || 0 | number : '1.0-2' : 'fr') : '-' }}
            </span>
        </ng-template>
    </mat-list-item>
</mat-list>
