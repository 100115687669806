import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { Socket } from 'ngx-socket-io';

@Injectable({ providedIn: 'root' })
export class StatusService {
    constructor(readonly http: HttpClient, private readonly socket: Socket) {}
    getMarketStatus(): Observable<any> {
        return this.http.get(environment.baseApiUrl + 'market/status');
    }

    getBalanceStatus(): Observable<any> {
        return this.http.get(environment.baseApiUrl + 'user/balance');
    }

    getUserStatus(): Observable<any> {
        return this.http.get(environment.baseApiUrl + 'user/info');
    }

    getMarketStatuses(): Observable<any> {
        return this.socket.fromEvent('market_status').pipe(map((data) => data as any));
    }
}
